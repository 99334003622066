import './backgroundImage.css'
import './homePage.css'
import Container from 'react-bootstrap/Container';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import Button from 'react-bootstrap/Button';

export function HomePage() {
    return (
        <div id='HomePage-Image'>
            <Container className='HomePage-Container'>
                <Row className='HomePage-Row'>
                    <Col sm={{ span: 8, offset: 2 }}>
                        <div className='HomePage'>
                            <header className="HomePage-Header">
                                <h1>NDVI Map Generator</h1>
                            </header>
                            <hr/>
                            <p>A simple tool for generating NDVI maps within a boundary extent. 
                            Intended to be used as a proof of concept only. 
                            Users and boundaries may be periodically deleted without notice. 
                            New satellite data is added periodically, but no guarantee is given that it will be up to date.</p>
                            <p>Sentinel-2 Cloud-Optimized GeoTIFFs sourced from <a href="https://registry.opendata.aws/sentinel-2-l2a-cogs" target="_blank">AWS Open Data</a></p>
                            <p>The code for this website can be found here <a href="https://github.com/alekLukanen/Sentinel-2-Mapping-Service">Github</a></p>
                        </div>
                    </Col>
                    <Col className="HomePage-Header" md={{ span: 6, offset: 3}}>
                        <Button className="Account-Button" variant="outline-primary" href='/r/login'>Login</Button>
                        <Button className="Account-Button" variant="outline-primary" href='/r/signup'>Signup</Button>
                    </Col>
                </Row>
                <a className="HomePage-Privacy" href="/r/privacy-policy">Privacy Policy</a>
            </Container>
        </div>
    )
}
