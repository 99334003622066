import './backgroundImage.css'
import './homePage.css'
import Container from 'react-bootstrap/Container';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';

export function PrivacyPolicy() {
    return (
        <div id='HomePage-Image'>
            <Container className='HomePage-Container'>
                <Row className='HomePage-Row'>
                    <Col sm={{ span: 8, offset: 2 }}>
                        <div className='HomePage'>
                            <header className="HomePage-Header">
                                <h1>Privacy Policy</h1>
                            </header>
                            <hr/>
                            <p>This website stores your username, password, boundaries, boundary names, boundary image statistics and NDVI images. 
                            This information is gathered and produced to enable operation of the site and provide basic functionality to you.
                            All information gathered is either provided directly by you or generated from your use of the sites features.
                            None of your information is ever shared or distributed in any way.</p>
                        </div>
                    </Col>
                </Row>
            </Container>
        </div>
    )
}
